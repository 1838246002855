








































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapActions } from 'vuex'
import VSwatches from 'vue-swatches'
import Ripple from 'vue-ripple-directive'
import 'vue-swatches/dist/vue-swatches.css'
import { computed } from '@vue/composition-api'
import {
  BListGroup,
  BListGroupItem,
  BDropdown,
  BDropdownDivider,
  BDropdownForm,
  BDropdownGroup,
  BDropdownItem,
  BFormCheckbox,
  BFormGroup,
  BTabs,
  BCardText,
  BTab,
  BButtonGroup,
  BButton
} from 'bootstrap-vue'
import { ETypeLine } from '@/types/api-orisis/enums/enums'
import draggable from 'vuedraggable'
// import PopupCreatePlanningLine from "@/components/planning/planning-settings/popup-planning-settings/PopupCreatePlanningLine.vue";
// import PopupPlanningViewUsersShared from "@/components/planning/planning-settings/popup-planning-settings/PopupPlanningViewUsersShared.vue";
import { LinePositionRequestDto, PlanningViewRequestDto } from 'Api'

@Component({
  components: {
    BListGroup,
    BListGroupItem,
    draggable,
    BDropdown,
    BDropdownDivider,
    BDropdownForm,
    BDropdownGroup,
    BDropdownItem,
    BFormCheckbox,
    BFormGroup,
    // PopupCreatePlanningLine,
    // PopupPlanningViewUsersShared,
    BTabs,
    BCardText,
    BTab,
    BButtonGroup,
    BButton,
    VSwatches
  },

  directives: {
    Ripple
  },
  methods: {
    ...mapActions([
      'storePlanningView',
      'fetchDetailPlanningView',
      'fetchPlanningLinesCollaborator',
      'fetchPlanningLinesFreeLine',
      'fetchPlanningLinesAffair',
      'fetchAllUsersByWorkspaceId'
    ])
  },
  computed: {
    ...mapGetters([
      'isPlanningViewCreating',
      'createdDataPlanningView',
      'workspaceSelected',
      'isLoadingDetail',
      'isLoadingPlanningView'
    ])
  }
})
export default class PopupCreatePlanningView extends Vue {
  //** Datas  */
  planningViewInstance: PlanningViewRequestDto = {
    id:0,
    name: '',
    workspaceId: '',
    linePositions: [],
    label: ''
  }
  search: string = ''
  $tabs: any
  id: any
  get planningLinesCollaborator (): any {
    return this.$store.getters.planningLinesCollaborator.map(elem => {
      // console.log(elem)
      return {
        position: this.planningViewInstance.linePositions!.length,
        name: elem.label as string,
        group: '',
        color: '',
        isLocked: false,
        typeLine: ETypeLine.CollaboratorLine,
        planningLineId: elem.id
      }
    })
  }
  query = {
    workspaceId: this.$store.getters.workspaceSelected
  }
  //** Created */
  created () {
    console.log('created', this.$route.params.id)
    let _this = this;
    this.$store.dispatch('fetchDetailPlanningView',  this.$route.params.id ? this.$route.params.id : 0).then(res=>{
    _this.planningViewInstance = res
    console.log('_this.planningViewInstance', _this.planningViewInstance)
    })
    this.$store.dispatch('fetchPlanningLinesCollaborator')
    this.$store.dispatch('fetchPlanningLinesFreeLine')
    this.$store.dispatch('fetchPlanningLinesAffair')
    // this.planningViewInstance = planningViewInstance
    // this.fetchAllUsersByWorkspaceId();
  }

  //** Methods */

  cancel () {
    this.$tabs.close()
  }

  addTitleToLinePosition (typeLine: ETypeLine) {
    let TitleLineTemplate: LinePositionRequestDto = {
      position: 0,
      typeLine: typeLine,
      planningLineId: '',
      isLocked: true
    }
    if (this.planningViewInstance.linePositions)
      this.planningViewInstance.linePositions.push(TitleLineTemplate)
  }
  getPlanningViewInstance (id: string): void {
    let planningViewInstance: PlanningViewRequestDto = {
      id:0,
      name: '',
      workspaceId: this.$store.getters.workspaceSelected,
      linePositions: []
    }
    this.planningViewInstance = planningViewInstance
  }
  removeItem (index) {
    this.planningViewInstance!.linePositions!.splice(index, 1)
  }
  onChange (evt) {
    console.log('onChange >', evt)
    console.log('planningViewInstance >', this.planningViewInstance)
    if (
      evt.added &&
      !this.planningViewInstance!.linePositions![evt.added.newIndex].position
    ) {
      // let planningLineStock = JSON.parse(JSON.stringify(this.planningViewInstance!.linePositions![evt.added.newIndex]))
      // this.planningViewInstance!.linePositions![evt.added.newIndex] = planningLineStock;
      // console.log("planningViewInstance2 >", this.planningViewInstance)
    }
  }
  getStoreDetailPlanningView (id: string): void {
    this.getPlanningViewInstance(id)
  }
  create (): void {
    console.log('planningViewData', this.planningViewInstance)
    this.planningViewInstance.id = this.planningViewInstance.id
      ? this.planningViewInstance.id
      : 0
    if (!this.planningViewInstance.id)
      this.$store.dispatch('storePlanningView', this.planningViewInstance)
    else this.$store.dispatch('updatePlanningView', this.planningViewInstance)
    this.$nextTick(() => {
      this.$tabs.close({ to: '/planning/settings/planning-views' })
    })
  }

  //** Watches */
  @Watch('$route.params.id')
  viewTargetChanged (id) {
    console.log('viewTargetChanged', id)
    this.$store.dispatch('fetchDetailPlanningView')
    this.$store.dispatch('fetchPlanningLinesCollaborator')
    this.$store.dispatch('fetchPlanningLinesFreeLine')
    this.$store.dispatch('fetchDetailPlanningView', {
      id: this.$route.params.id ? this.$route.params.id : null,
      that: this
    })
    // this.fetchAllUsersByWorkspaceId({query: this.query, that:this});
  }
  @Watch('createdDataPlanningView')
  propertyChanged () {
    if (
      this.$store.getters.planningView !== null &&
      (!this.$store.getters.isPlanningViewCreating as Boolean)
    ) {
      this.$bvToast.toast(
        'La vue de planning "' +
          this.$store.getters.planningView.name +
          '" a bien été créée',
        {
          title: `Création de la vue de planning`,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true,
          variant: 'success'
        }
      )
      this.$router.push('planningViews')
    }
  }
}
